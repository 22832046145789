import React from "react";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Alert from "@mui/material/Alert";
import { isLoggedIn } from "../lib/user.auth";

const Success = () => {
  return isLoggedIn() ? (
    <Layout>
      <Seo title="You are now on the pro plan" />
      <h1>Pro features enabled</h1>
      <Alert severity="success">
        Super! <span>🔥</span>
        <br />
        You're running the pro plan now!
      </Alert>
      <br />
      <a href="/dashboard" title="dashboard">
        Go to the dashboard
      </a>{" "}
      to manage your accounts.
    </Layout>
  ) : (
    <Layout>
      <Seo title="You are not logged on!" />
      <h1>Please login</h1>
      <a href="/dashboard" title="dashboard">
        Go to login
      </a>
      .
    </Layout>
  );
};
export default Success;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
